import React, { Component } from 'react'
import { NavLink, Switch, Route, Redirect, BrowserRouter } from 'react-router-dom'


import ServicesSection from '../sections/ServicesSection';

import { Profilaktyka, Ortopedia, Diagnostyka, RTG, USG, Czipowanie, Stomatologia } from '../sections/SubSections'


const localRoutes = [
    {
        to: 'profilaktyka',
        name: 'Profilaktyka',
        component: Profilaktyka
    }, {
        to: 'ortopedia',
        name: 'Ortopedia',
        component: Ortopedia
    }, {
        to: 'diagnostyka',
        name: 'Diagnostyka',
        component: Diagnostyka
    }, {
        to: 'rtg',
        name: 'RTG',
        component: RTG
    }, {
        to: 'usg',
        name: 'USG',
        component: USG
    }, {
        to: 'czipowanie',
        name: 'Czipowanie',
        component: Czipowanie
    }, {
        to: 'stomatologia',
        name: 'Stomatologia',
        component: Stomatologia
    }
]

const LocalNavigation = ({pathname, routes}) => {
    console.log('LOCAL NAVIGATION PRINTING!', routes)
    return (
        <nav className="local-nav">
            {routes.map(({to, name}, index) => 
                <NavLink activeClassName={'active'} key={name+index} to={`${pathname}/${to}`} className="local-nav__item">{name}</NavLink>
            )}
        </nav>
    )
}


class UslugiPage extends Component {
    render() {
        return (
            <div>
                <LocalNavigation pathname={'/uslugi'} routes={localRoutes}/>
                <div className="services-section">
                    <Switch>
                        <Redirect exact from="/uslugi" to="/uslugi/profilaktyka"/>
                        {localRoutes.map(({to, component},index) => 
                            <Route key={'r'+index} path={`/uslugi/${to}`} component={component}/>
                        )}
                    </Switch>                
                </div>
            </div>
        )
    }
}

export default UslugiPage