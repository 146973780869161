import React from 'react'
import { Link } from 'react-router-dom'

// const CasesArticle = ({title, copy, date, author}) => {
const CasesArticle = ({title, copy, date, author}) => {
    return (
        <div className="cases-article">
            <div className="cases-article__container">
                <div className="cases-article__image">

                </div>
                <div className="cases-article__content">
                    <div className="cases-article__title">
                        {title}
                    </div>
                    <div className="cases-article__copy">
                        {copy}
                    </div>
                    <div className="cases-article__info">
                        <span className="cases-article__date">
                            {date}
                        </span>
                        |
                        <span className="cases-article__author">
                            {author}
                        </span>
                    </div>
                </div>
                <Link className="cases-article__cta" to="/article/pies-jakis-tam-fajny">
                    <span className="button button--rounded">
                    </span>
                </Link>
            </div>
        </div>
    )
}

export default CasesArticle