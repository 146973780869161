import React, { Component } from 'react'

class MapSection extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        let markercenter = {lat: 49.985495, lng: 20.065417}
        window.initMap(markercenter, markercenter)
    }

    render() {
        return (
            <div className="map-section">
                <div className="map-section__map" id="map">
                </div>
            </div>
        )
    }
}

export default MapSection