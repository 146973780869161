import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class FooterSection extends Component {
    render() {
        return (
            <footer className="footer-section">
                <div className="footer-section__container">
                    <div className="footer-section__copy">
                        <h2>Gabinet weterynaryjny ARWET</h2>
                        <p>ul. Sienkiewicza 25 <br/>
                        32-020 Wieliczka</p>
                        <a href="mailto:recepcja@arwet.pl">recepcja@arwet.pl</a>
                    </div>
                      <div className="footer-section__menu">
                         <h1>Menu</h1>
                        <nav className="navigation">
                            <Link className="navigation__item" to="/">Strona główna</Link>
                            {/* <Link className="navigation__item" to="/zespol">Zespół</Link> */}
                            <Link className="navigation__item" to="/uslugi">Usługi</Link>
                            {/* <Link className="navigation__item" to="/artykuly">Artykuły</Link> */}
                            <Link className="navigation__item" to="/kontakt">Kontakt</Link>
                        </nav>
                    </div>
                    <div className="footer-section__facebook">
                        <div className="footer-section__payment">
                            Akeceptujemy karty płatnicze
                            <img src="/resources/images/karty.png" alt=""/>
                        </div>
                        {/*TODO Facebook social*/}
                    </div>
                </div>
            </footer>
        )
    }
}

export default FooterSection