import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class TopSection extends Component {
    render() {
        return (
            <header className="top-section">
                <div className="top-section__header">
                    <div className="top-section__header__item open-info">
                        <b>GODZINY OTWARCIA</b><br/>
                        PN-PT: 8.00-20.00 <br/>
                        SOB: 8.00-14.00
                    </div>
                    <div className="top-section__header__item logo">
                        <img src="/resources/images/logo.png" alt="Arwet logo"/>
                    </div>
                    <div className="top-section__header__item alarm-contact">
                        <b>TELEFON</b> <br/>
                        <span className="alarm-contact__number">
                            +48 12 278 1800
                        </span>
                    </div>
                </div>
                <nav className="top-section__navigation navigation">
                    <Link className="top-section__navigation__item" to="/"><span className="top-section__navigation__item__copy">Strona główna</span></Link>
                    {/* <Link className="top-section__navigation__item" to="/zespol"><span className="top-section__navigation__item__copy">Zespół</span></Link> */}
                    <Link className="top-section__navigation__item" to="/uslugi"><span className="top-section__navigation__item__copy">Usługi</span></Link>
                    {/* <Link className="top-section__navigation__item" to="/artykuly"><span className="top-section__navigation__item__copy">Artykuły</span></Link> */}
                     <Link className="top-section__navigation__item" to="/kontakt"><span className="top-section__navigation__item__copy">Kontakt</span></Link>  
                </nav>
            </header>
        )
    }
}

export default TopSection