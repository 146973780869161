import React, { Component } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import HomePage from './pages/HomePage'
import ZespolPage from './pages/ZespolPage'
import UslugiPage from './pages/UslugiPage'
import ArtykulyPage from './pages/ArtykulyPage'
import ArtykulPage from './pages/ArtykulPage'
import KontaktPage from './pages/KontaktPage'

import TopSection from './sections/TopSection'
import FooterSection from './sections/FooterSection'

import articles from '../utils/articles'

export default class App extends Component {
	constructor(props) {
		super(props)

		this.state = {}
	}

	render() {
		return (
			<div className="app">
				<TopSection />
				<Switch>
					<Route exact path="/" component={HomePage} />
					<Route path="/zespol" component={ZespolPage} />
					<Route path="/uslugi" component={UslugiPage} />
					{/* <Route exact path="/artykuly" component={ArtykulyPage} /> */}
					{/* <Route
						path="/artykuly/tta_rapid_w_pytaniach_i_odpowiedziach" 
						component={() => {
							return <div>SUPER TTA RAPID JEST</div>
						}} /> */}
						
					{articles.map((article, index) => {
						console.log(article)
						return <Route
							key={`route_${index}`}
							path={article.link}
							component={() => {
								return <ArtykulPage
									article={article}
								/>}
							}
						/>
						})
					}
					<Route path="/artykuly" component={ArtykulyPage} />
					<Route path="/artykuly" component={ArtykulyPage} />
					<Route path="/kontakt" component={KontaktPage} />
				</Switch>
				<FooterSection />
			</div>
		)
	}
}