import React from 'react';

export const Profilaktyka = () => {
    return (
        <div>
            <div className="services-section__content">
                <h1>Profilaktyka</h1>
                <p>Oferujemy Państwu szeroko pojętą profilaktykę, która obejmuje:</p>
                <ul>
                    <li>odrobaczanie,</li>
                    <li>szczepienia,</li>
                    <li>zwalczanie pasożytów zewnętrznych (pchły, kleszcze, świerzb, nużeniec),</li>
                    <li>okresowe badania (wskazane głównie u starszych pacjentów).</li>
                </ul>
                <h2>Odrobaczanie</h2>
                <p>Dostępne środki przeciwpasożytnicze występują w postaci preparatów doustnych (tabletki, pasty), kropli do wylewania na skórę typu "spot-on", a także w formie injekcji.</p>
                <p>O częstotliwości odrobaczania i preparacie jaki ma być zastosowany, decyduje lekarz weterynarii, który bierze pod uwagę m.in. takie aspekty jak: wiek zwierzęcia, jego tryb życia ( np. kot wychodzący/niewychodzący ), stan zdrowia. U dorosłych zwierząt najbardziej prawidłowym postępowaniem jest badanie kału ( zebranego z 3 dni ) i na tej podstawie ocenia się czy konieczne jest podanie środków przeciw pasożytom wewnętrznym.</p>
                <p>Należy pamiętać, że pasożyty wewnętrzne (np. glisty, tasiemce) występujące u zwierząt, stanowią bezpośrednie zagrożenie także dla właścicieli, dlatego tak ważna jest dbałość o regularne badanie kału naszych milusińskich, bądź też okresowe odrobaczanie zgodnie z zaleceniami lekarza weterynarii.</p>

                <h2>Szczepienie</h2>
                <p>Jeden z podstawowych zabiegów profilaktycznych. Odpowiednio przeprowadzony cykl szczepień pozwala znacząco zminimalizować ryzyko rozwoju choroby zakaźnej u Państwa pupila.
                Podczas pierwszej wizyty w lecznicy, lekarz ustala program szczepień najlepszy dla danego zwierzęcia (wiek zwierzęcia, jego status immunologiczny, tryb życia).</p>

                <p>Na 7-10 dni przed każdym szczepieniem zwierzę należy odrobaczyć / zbadać kał.</p>
            </div>
            <table className="services-section__table"
            cellSpacing="0">
                <thead>
                    <tr>
                        <th></th>
                        <th>PIES</th>
                        <th>KOT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Szczepienia obowiązkowe</td>
                        <td>wścieklizna (co roku)	</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td rowSpan="3">Szczepienia zalecane</td>
                        <td>nosówka</td>	
                        <td>panleukopenia</td>
                    </tr>
                    <tr>
                        <td>parwowiroza</td>
                        <td>herpeswiroza</td>
                    </tr>
                    <tr>
                        <td>choroba Rubartha</td>
                        <td>kaliciwiroza</td>
                    </tr>
                    <tr>
                        <td rowSpan="4">Dodatkowe szczepienia zalecane tylko dla niektórych zwierząt</td>
                        <td>leptospiroza</td>
                        <td>białaczka</td>
                    </tr>
                    <tr>
                        <td>herpeswiroza</td>
                        <td>chlamydofiloza</td>
                    </tr>
                    <tr>
                        <td>grzybica skórna</td>
                        <td>grzybica skórna</td>
                    </tr>
                    <tr>
                        <td>kaszel kenelowy</td><td></td>
                    </tr>
                </tbody>
            </table>
            <div className="services-section__content">
                <p><b>Ważne: regularne szczepienie zwierzaka to przede wszystkim regularne badanie kliniczne, które pozwala na wczesne wykrycie ewentualnych problemów zdrowotnych.</b></p>
            </div>  
        </div>
    )
}

export const Ortopedia = () => {
    return (
        <div className="services-section__content">
            <h1>Ortopedia</h1>
            <p>Zachowawcze i operacyjne leczenie złamań, zwichnięć i chorób zwyrodnieniowych i rozwojowych narządu ruchu, badania w kierunku dysplazji stawów łokciowych i biodrowych.</p>
            <img className="services-section__image"src="/resources/images/ttarapid1.jpg" alt=""/>
            <p>Wykonujemy również stabilizację stawu kolanowego u zwierząt z zerwanym lub częściowo uszkodzonym więzadłem krzyżowym przednim metodą TTA RAPID</p>
            <img className="services-section__image"src="/resources/images/ttarapid2.jpg" alt=""/>
        </div>
    )
}

export const Diagnostyka = () => {
    return (
        <div className="services-section__content">
            <h1>Diagnostyka laboratoryjna</h1>
            <h4>Badanie krwi:</h4>
            <ul>
                <li>morfologiczne z rozmazem</li>
                <li>biochemiczne</li>
            </ul>
            <img className="services-section__image--small"src="/resources/images/lab1.jpg" alt=""/>
            
            <h4>Badanie kału:</h4>
                <ul>
                    <li>bezpośrednie badanie mikroskopowe</li>
                    <li>fecalizer</li>
                    <li>test wytrawiania</li>
                </ul>
            <h4>Badanie moczu ogólne i mikroskopowe badanie osadu.</h4>
            <img className="services-section__image--small"src="/resources/images/lab2.jpg" alt=""/>
            
            <h4>Badania cytologiczne</h4>
            <h4>Badanie zeskrobin skórnych</h4>
            <img className="services-section__image--small"src="/resources/images/lab3.jpg" alt=""/>
            
            <h4>Pobieranie i przygotowanie materiału do badań bakteriologicznych, mykologicznych i histopatologicznych.</h4>
        </div>
    )
}

export const RTG = () => {
    return (
        <div className="services-section__content">
            <h1>RTG</h1>
            <p>Od niedawna posiadamy nowoczesny system radiografii bezpośredniej MEX+ 1717 SGC.
            Przy użyciu niewielkiej dawki promieniowania umożliwia on bardzo dokładną, szybką oraz bezpieczniejszą dla pacjenta diagnostykę</p>
        </div>
    )
}

export const USG = () => {
    return (
        <div className="services-section__content">
            <h1>USG (Ultrasonografia)</h1>
            <p>USG jest obecnie jedną z podstawowych metod diagnostycznych w weterynarii. Dzięki diagnostyce obrazowej możemy dokładnie ocenić strukturę poszczególnych narządów oraz ewentualne nieprawidłowości w ich budowie co przekłada się na szybszą ocenę schorzenia i postawienie trafnej diagnozy.</p>
            <p>Badanie ultrasonograficzne jest badaniem zupełnie bezinwazyjnym, bezbolesnym i błyskawicznym. Ta metoda diagnostyczna wykorzystuje zjawisko rozchodzenia się, rozpraszania oraz odbicia fali ultradźwiękowej na granicy badanego ośrodka. Nasza lecznica wyposażona jest w wysokiej klasy aparat ultrasonograficzny Sonix MDP, który zapewnia pełen zakres badań ultrasonograficznych również z użyciem Dopplera.</p>
       </div> 
    )
}

export const Czipowanie = () => {
    return (
        <div className="services-section__content">
            <h1>Wystawianie paszportów i czipowanie zwierząt</h1>
            <p>Elektroniczne znakowanie psów (czipowanie) jest niezbędne by podróżować ze zwierzęciem poza granicami naszego Kraju. Czip z zakodowanym 15 cyfrowym numerem jest wielkości ziarenka ryżu, umieszczany na wysokości łopatki podskórnie nie przeszkadza zwierzęciu i nie powoduje reakcji alergicznych. Zabieg wszczepienia mikroprocesora jest prawie bezbolesny, a zwierzę reaguje jak przy zwykłym zastrzyku. Czipowanie jest istotne również w momencie gdy nasz pies/kot zaginie lub kiedy znajdziemy zwierzę.</p>
            <p><b>PAMIĘTAJ, ŻE SAM CZIP NIC NIE DA! Istotna jest rejestracja pupila w międzynarodowej bazie SAFE-ANIMAL (lub innej) </b></p>
            <p>Czipując w naszej lecznicy wpis do bazy SAFE-ANIMAL Gratis.</p>
       </div> 
    )
}

export const Stomatologia = () => {
    return (
        <div className="services-section__content">
            <h1>Stomatologia</h1>
            <ul>
                <li>porady dotyczące profilaktyki stomatologicznej (żywienia, dodatków żywieniowych, czyszczenia zębów i koniecznych do tego celu past, żeli i płynów)</li>
                <li>usunięcie kamienia nazębnego</li>
                <li>polerowanie koron</li>
                <li>fluoryzacja</li>
                <li>ekstrakcja zębów</li>
                <li>diagnostyka dodatkowa (zdjęcia RTG, biopsja i usuwanie zmian rozrostowych)</li>
            </ul>
       </div> 
    )
}
