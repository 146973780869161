import React, { Component } from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom'


import MapSection from '../sections/MapSection';

const KotaktContent = () => {
    return (
        <div>
            <div>
                <h1>Kontakt</h1>

                <p><b>GABINET WETERYNARYJNY ARWET</b><br/>
                    UL. SIENKIEWICZA 25<br/>
                    32-020 WIELICZKA<br/>
                    TEL. STACJONARNY: (12)278-18-00<br/>
                    TEL: +48 12 278 1800<br/>
                    <a href="mailto:recepcja@arwet.pl">recepcja@arwet.pl</a>
                </p>
            </div>
            <MapSection/>
        </div>
    )
}

class KontaktPage extends Component {
    render() {
        return (
             <div>
                <Switch>
                    <Route path="/" component={KotaktContent}/>
                </Switch>                
            </div>


        )
    }
}

export default KontaktPage