module.exports = [{
    id: 23423,
    imgUrl: '/resources/images/7f963377daab750067659d53fc7c9d3e.jpeg',
    title: 'TTA RAPID w pytaniach i odpowiedziach',
    copy: 'Gdy w naszej lecznicy odbył się pierwszy w Polsce zabieg metodą TTA Rapid, towarzyszyły mu wielkie emocje.',
    date: '14 lipca 2017',
    content: `
        <p>Z jednej strony, czuliśmy olbrzymią radość: w końcu mogliśmy pomóc choremu pacjentowi, wykorzystując do tego innowacyjną metodę jaką jest TTA Rapid; z drugiej strony, na efekty zabiegu musieliśmy</p>
        <p>poczekać jakiś czas. Był to pionierski zabieg w Polsce i jak to w takich przypadkach bywa, w napięciu oczekiwaliśmy na rezultaty.</p>
        <p>
        <br>
        </p>
        <p>Dzisiaj wiemy, że było warto! Zagraniczne szkolenia, zakup wyposażenia, narzędzi oraz implantów niezbędnych do przeprowadzenia operacji, a także zaangażowanie opiekunów psa w proces rehabilitacji – to wszystko zaowocowało powrotem Dextera, pierwszego w Polsce psa, operowanego metodą TTA Rapid, do pełnej sprawności. Biega wesoły, razem ze swoimi psimi towarzyszami, wśród koni i każdego dnia daje swoim opiekunom powody do radości.</p>
        <p>&nbsp;</p>
        <p>TTA Rapid jest jedną z metod polegających na wykonaniu osteotomii kości piszczelowej. Mechanika stawu kolanowego po przeprowadzonym zabiegu ulega zmianie i pomimo braku więzadła, umożliwia psu powrót do normalnego trybu życia. Zabieg polega na implantacji specjalnie zaprojektowanej klatki z biokompatybilnego stopu tytanu. Implantacja skutkuje przemieszczeniem guzowatości piszczelowej i zmianie momentu sił działających na kość piszczelową.</p>
        <p>
        <br>
        </p>
        <p>Na końcu artykułu, umieściliśmy animację przedstawiającą kolejne etapy zabiegu.&nbsp;</p>
        <p>Obecnie rutynowo stosujemy metodę TTA Rapid u psów z uszkodzonym więzadłem krzyżowym przednim. Po wielu przeprowadzonych zabiegach, wiemy dużo więcej zarówno o samej technice operowania, jak i o okresie pooperacyjnym. Rezultaty przerosły nasze najśmielsze oczekiwania i chociaż czasami nie obywa</p>
        <p>się bez drobnych powikłań, to jak do tej pory wszystkie operowane przez nas zwierzęta wróciły do pełnej sprawności.</p>
        <p>
        <br>
        </p>
        <p>TTA Rapid nie jest jedyną metodą, jaką operujemy psy z uszkodzonym więzadłem krzyżowym przednim, korzystamy również z techniki szwu bocznego (ang. Lateran Suture –LS) i Over the Top.</p>
        <p>Tak więc na pytanie „Czy możemy skutecznie pomóc psu z uszkodzonym więzadłem krzyżowym przednim?” Odpowiadamy - Możemy!</p>
        <p>Obecnie wiemy dostatecznie dużo o tym jak jest zbudowany i jak pracuje staw kolanowy aby zapewnić naszym pacjentom powrót do pełnej sprawności.</p>
        <p>
        <br>
        </p>
        <p>Częstą przyczyną pojawienia się u lekarza weterynarii psa, który kuleje na kończynę miedniczną, jest problem z więzadłem krzyżowym przednim (Cranil Crucial Ligament – w skrócie CCL). Więzadło to jest jednym z ważniejszych elementów stabilizujących staw kolanowy. Nie zawsze oznacza, że więzadło jest całkowicie zerwane, może być naderwane lub naciągnięte, ale z całą pewnością, jeżeli pies kuleje, powodem tego jest ból. Ból, który towarzyszy uszkodzeniom CCL jest wynikiem niestabilności stawu kolanowego. Dość często towarzyszy temu uszkodzenie łąkotek.</p>
        <p>&nbsp;</p>
        <p>Poniżej film który ukazuje budowę stawu kolanowego u psa oraz problemy, które pojawiają się, gdy uszkodzeniu ulegnie CCL:</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/yjFsmggQyAk" frameborder="0" allowfullscreen></iframe>
        <p>Dlaczego dochodzi do uszkodzenia więzadła krzyżowego przedniego? Może to być spowodowane wieloma czynnikami. Ma na to wpływ kondycja psa, tryb życia, rasa, a także budowa anatomiczna stawu kolanowego. Wbrew pozorom, uraz powodujący zerwanie zdrowego więzadła jest stosunkowo rzadki. O wiele częściej mamy do czynienia z mikrourazami, które w efekcie prowadzą do znacznego osłabienia struktury</p>
        <p>więzadła. Osłabione więzadło nie spełnia swojej funkcji, która polega na stabilizacji stawu. Jeżeli wytrzymałość spada poniżej pewnej granicy dochodzi do częściowego lub całkowitego zerwania. Jest to moment kiedy najczęściej pojawiają się bardzo wyraźne objawy, takie jak: posmutnienie, niechęć do ruchu, kulawizna czy unoszenie kończyny w celu jej odciążenia. Czasami pojawia się obrzęk stawu kolanowego i jego podwyższona</p>
        <p>
        <br>
        </p>
        <p><strong>DLACZEGO?</strong>
        </p>
        <p><strong>JAK TO ZDIAGNOZOWAĆ?</strong>
        </p>
        <p>
        <br>
        </p>
        <p>Do postawienia ostatecznej diagnozy konieczne jest przeprowadzenie badania ortopedycznego. Jednym</p>
        <p>z elementów tego badania jest test kompresji i test szufladowy przedstawione w poniższej wizualizacji:</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/Svgg7ii7jkg" frameborder="0" allowfullscreen></iframe>
        <p>
        <br>
        </p>
        <p>W niektórych przypadkach do wykonania testów konieczne jest farmakologiczne uspokojenie zwierzęcia. Pozwala to na wykonanie badania bez zadawania bólu, znosi napięcie mięśni, co w znacznym stopniu ułatwia przeprowadzenie testów. Pozwala to też, jeśli zachodzi taka konieczność, na wykonanie dodatkowych badań takich jak badanie RTG, USG czy punkcja stawu. Badania te, a zwłaszcza badanie radiologiczne, dostarczają informacji o współistniejącym zwyrodnieniu stawu oraz umożliwia wykonanie obliczeń koniecznych do przeprowadzenia zabiegu operacyjnego.</p>
        <p>Poniżej przykładowe zdjęcia RTG, na których wykonano obliczenia wielkości wysunięcia guzowatości piszczelowej.</p>
        <p>
        <br>
        </p>
        <p><img src="/resources/images/b98e75a4e2f7a2d648fd1771b08cb439.png">
        </p>
        <p>Tego typu wyliczenia są niezbędne do wykonania zabiegu metodą TTA Rapid.</p>
        <p>&nbsp;</p>
        <p><strong>DLA KOGO TTA RAPID?</strong>
        </p>
        <p>
        <br>
        </p>
        <p>Dla wielu, ale nie dla wszystkich. Psy które kwalifikujemy do operacji metodą TTA Rapid, w większości wypadków należą do ras dużych i olbrzymich, chociaż rasy średnie i małe również mogą być operowane tą metodą. Są to zwierzęta w różnym wieku, najczęściej prowadzące aktywny tryb życia, pracujące oraz uprawiające sport. Dla tej grupy zwierząt bardzo istotny jest szybki powrót do zdrowia oraz odzyskanie pełnej sprawności. Wiemy, że po zakończonym leczeniu tą metodą, zwierzę nie będzie musiało oszczędzać operowanej kończyny. TTA Rapid ma również zastosowanie u psów, których właściciele chcą zminimalizować konieczność powtórnych ingerencji chirurgicznych.</p>
        <p>Psy ras miniaturowych oraz koty, z powodzeniem można zoperować innymi metodami np. LS, czy Over The Top. U tych zwierząt nie występują tak wielkie siły działające w operowanym stawie, więc ryzyko niepowodzenia jest mniejsze.</p>
        <p>
        <br>
        </p>
        <p>Przeciwwskazaniem bywają również towarzyszące choroby, brak możliwości zapewnienia odpowiedniej opieki w okresie rehabilitacji oraz ograniczenia finansowe.</p>
        <p>
        <br>
        </p>
        <p>Poniżej animacja przedstawiająca kolejne etapy zabiegu.</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/xq6JuEiG7HE" frameborder="0" allowfullscreen></iframe>
        <p>
        <br>
        </p>
        <p>
        <br>
        </p>
        <p><img src="/resources/images/fb75d6f466e267e3792d924245f800ad.png">
        </p>
        <p>&nbsp;</p>`,
    link: '/artykuly/tta_rapid_w_pytaniach_i_odpowiedziach'   
}, {
    id: 2,
    imgUrl: '/resources/images/b134b8f12872686530a7a1f234048236.jpeg',
    title: 'TTA Rapid - Weterynaria w praktyce',
    copy: 'TTA Rapid – ciekawa alternatywa do operacji uszkodzonego więzadła krzyżowego przedniego',
    date: '13 sierpnia 2017',
    content: `
        <h1>TTA Rapid –&nbsp;ciekawa alternatywa do operacji uszkodzonego więzadła krzyżowego przedniego</h1>
        <h2>Artykuł autorstwa&nbsp;lek. wet. Rafał Korta zamieszczony w magazynie "Weterynaria w praktyce"</h2>
        <p>
        <br>
        </p>
        <p>Zabieg Tibial Tuberosity Advancement Rapid (TTA Rapid) – jest jedną z najnowszych modyfikacji klasycznej techniki Tibial Tubrosity Advancement (TTA),która jest powszechnie wykorzystywana przez wielu lekarzy weterynarii.</p>
        <p>&nbsp;Celem tej metody jest zmiana kąta, który zawarty jest pomiędzy powierzchnią stawową kości piszczelowej (TP – Tibial Plateau) a więzadłem prostym rzepki, a tym samym likwidacja siły wypychającej część bliższą kości piszczelowej w kierunku dogłowowym (CTT – Cranial Tibial Thrust) (1). Eliminacja siły wypychającej powoduje, że podczas obciążania kończyny staw kolanowy jest stabilny. Należy nadmienić, iż zabieg operacyjny nie likwiduje niestabilności stawu w czasie badania palpacyjnego.</p>
        <p>&nbsp;Metoda została opracowana w 2012 roku przez dr. Yvesa Samoya z wydziału Medycyny Weterynaryjnej Uniwersytetu w Gandawie, przy współpracy z Veterinary Instrumentation.</p>
        <p>&nbsp;Najbardziej istotne różnice pomiędzy TTA a TTA Rapid wynikają ze sposobu implantacji klatki oraz braku</p>
        <p>innych elementów pozostawianych w kości po zabiegu. Założenie klatki zajmuje znacznie mniej czasu, dzięki czemu cały zabieg jest krótszy, a ilość leków zużytych do podtrzymania narkozy jest mniejsza. Relatywnie niższa liczba możliwych niepowodzeń oraz opracowane procedury postępowania w przypadku ich wystąpienia czynią zabieg TTA Rapid atrakcyjną alternatywą dla operującego. Krótszy czas pracy chirurga oraz niższe koszty znieczulenia mają również odzwierciedlenie w cenie całej procedury.</p>
        <p><strong>Anatomia stawu kolanowego</strong>
        </p>
        <p>Staw kolanowy (articulatio gnus) jest stawem złożonym, tworzą go trzy kości: koniec dalszy kości udowej (os femur), koniec bliższy kości piszczelowej (os tibia) oraz rzepka (patella). Wyrożnia się w nim staw udowo-rzepkowy (articulatio femoropatellaris) oraz staw udowo-piszczelowy (articulatio femorotibialis).</p>
        <p>Staw udowo-piszczelowy jest stawem, w którym wypukłe powierzchnie stawowe kości udowej spotykają się z płaskimi kłykciami kości piszczelowej. Niedopasowanie tych powierzchni jest niwelowane przez łąkotki (menisci). Zbudowane z chrząstki włóknistej łąkotki posiadają panewkowato wklęsłą powierzchnię bliższą, ktora kontaktuje powierzchniami stawowymi kłykcia bocznego i przyśrodkowego kości udowej, a także płaską powierzchnię dalszą zwróconą w stronę kłykci kości piszczelowej.</p>
        <p>&nbsp;Wśród więzadeł stawu udowo-piszczelowego można wyróżnić więzadła właściwe stawu oraz więzadła stabilizujące łąkotki. Więzadła właściwe stawu to: więzadła poboczne (ligg. collateralia laterale et mediale), więzadła krzyżowe dogłowowe, czyli przednie (lig. Cruciatum craniale – LCC), oraz więzadło krzyżowe doogonowe, czyli tylne (lig. cruciatum caudale).</p>
        <p>&nbsp;Więzadło krzyżowe przednie ma swój początek na powierzchni międzykłykciowej kłykcia bocznego kości udowej i kierując się dogłowowo i przyśrodkowo, kończy się na area inercondylaris centralis tibiae. W dużym uproszczeniu można powiedzieć, że więzadło ma ułożenie takie jak ręka w kieszeni spodni. Dzieli się ono na grube pasmo cranio-medialne, pasmo to jest naprężone zarówno podczas prostowania, jak i zginania, oraz na cieńsze pasmo caudo-lateralne, które jest naprężone tylko podczas prostowania i wiotczeje przy zgięciu stawu. Więzadło krzyżowe przednie ogranicza przesunięcie w kierunku dogłowowym kości piszczelowej względem kości udowej. Razem z więzadłem krzyżowym tylnym więzadło krzyżowe przednie limituje wewnętrzną rotację kości piszczelowej. Więzadło krzyżowe tylne przebiega od powierzchni międzykłykciowej kłykcia przyśrodkowego kości udowej do area intercondylaris caudalis oraz do wcięcia podkolanowego incisura poplitea tibiae.</p>
        <p>&nbsp;Więzadła łąkotek łączą je z kością piszczelową, są to od przodu lig. tibiale craniele menisci lateralis et medialis, natomiast z tyłu – lig. tibiale caudale menisci lateralis et medialis. Dodatkowo łąkotka boczna dzięki lig. meniscofemorale</p>
        <p>jest połączona z kością udową, a pomiędzy łąkotkami od strony przedniej przebiega lig. transversum genus.</p>
        <p>
        <br>
        </p>
        <p><strong>Etiologia zerwania więzadła</strong>
        </p>
        <p>Istnieją dwie podstawowe grupy przyczyn uszkodzenia LCC – urazy oraz postępujące procesy zwyrodnieniowe. Często dochodzi do urazu więzadła, które już wcześniej było osłabione przez procesy degeneracyjne. Współcześnie uważa się, że zwyrodnienie jest główną przyczyną uszkodzeń LCC i może być wynikiem procesów starzenia, nieprawidłowej budowy kończyn miednicznych oraz immunozależnej artropatii (1). Wzrost kąta płaszczyzny piszczeli (Tibila Plateau Angle – TPA) jest uznawany za czynnik sprzyjający uszkodzeniu więzadła (2), jednak istnieją opracowania podważające</p>
        <p>tę teorię (1). Zerwanie lub częściowe uszkodzenie LCC będące wynikiem urazu może wystąpić u psów w niezależnie od ich wieku. Wystąpienie ostrego urazu jest sytuacją, kiedy przyczyna uszkodzenia LCC jest łatwa do zidentyfikowania. Niestety, najczęściej uszkodzeniu lub zerwaniu LCC</p>
        <p>sprzyja wiele rożnych czynników, niektóre z nich, np. procesy autoimmunologiczne lub mikrourazy spowodowane nieprawidłowościami anatomicznymi, działają przez długi czas, w konsekwencji doprowadzając do znacznego osłabienia wytrzymałości więzadła i zwiększając jego podatność na urazy. Ustalając rokowanie u zwierząt z uszkodzonym LCC, należy brać pod uwagę udział procesow zwyrodnieniowych jako głównego czynnika przyczynowego.</p>
        <p>&nbsp;</p>
        <p><strong>Diagnostyka</strong>
        </p>
        <p>Kwalifikując zwierzę do zabiegu, powinniśmy ocenić stopień uszkodzenia LCC i ustalić zaawansowanie zmian zwyrodnieniowych. Ma to duże znaczenie w ustaleniu optymalnego postępowania przed leczeniem i po zabiegu. Rutynowe badanie ortopedyczne, poprzedzone szczegółowym wywiadem, pozwala na wstępne postawienie rozpoznania. Najczęściej mamy do czynienia ze zwierzętami, u których postawienie</p>
        <p>diagnozy nie nastręcza problemu. Zawsze należy zbadać obydwa stawy kolanowe, a zdrowy staw powinien stanowić punkt odniesienia. Dodatnie wyniki testów kompresji i szufladowego są wystarczające, aby wykryć uszkodzenie (3). Czasami jednak, głownie w przypadku częściowego uszkodzenia więzadła, testy mające wykazać niestabilność stawu kolanowego nie są wystarczające, aby postawić diagnozę.</p>
        <p>Niekiedy u zwierząt, u których proces chorobowy trwa długo, dochodzi do zmian w obrębie torebki stawowej, powodujących jej zgrubienie, co może dawać fałszywe wrażenie stabilnego stawu.</p>
        <p>Jedną z metod pomocnych w potwierdzeniu, czy mamy do czynienia z uszkodzeniem LCC, jest artroskopia. Diagnostyka artroskopowa pozwala również, oprócz oceny stopnia uszkodzenia LCC, zdiagnozować współistniejące problemy w stawie kolanowym (4). Dodatkowo podczas tego zabiegu</p>
        <p>można dokonać usunięcia resztek zerwanego więzadła, operacji łąkotek i innych zabiegów, które są niezbędne do poprawy stanu stawu kolanowego. Konieczne jest również wykonanie</p>
        <p>zdjęć RTG stawu kolanowego w projekcji bocznej i strzałkowej. Radiogramy pomogą uwidocznić zmiany wtórne w obrębie stawu, posłużą one także do obliczeń poprzedzających zabieg.</p>
        <p><img src="/resources/images/b134b8f12872686530a7a1f234048236.jpeg">
        </p>
        <p><em style="color: rgb(7, 7, 7);">Ryc. 1. Narzędzia: 1) prowadnica (szablon) do wykonania otworu Maqueta, 2) ogranicznik do szablonu, 3) stopa prowadnicy piły, 4) prowadnica piły oscylacyjnej, 5) dźwignie służące do otwarcia osteotomii, 6) przyrząd do modelowania&nbsp;kształtu uszu klatek</em>
        </p>
        <p>
        <br>
        </p>
        <p><strong style="color: rgb(51, 51, 153);">Metody leczenia</strong>
        </p>
        <p>Istnieją zachowawcze i operacyjne metody leczenia psa z uszkodzonym więzadłem krzyżowym przednim. Istotą tych metod jest takie ograniczenie ruchu, które pozwala na regenerację struktur stawu kolanowego. Czasami w wyniku leczenia zachowawczego dochodzi do zmian w obrębie struktur stabilizujących staw, np. zgrubienia torebki stawowej. Zmiany te skutkują przywróceniem częściowej lub całkowitej stabilności</p>
        <p>stawu. Na uwagę zasługują ortezy służące do nieoperacyjnego leczenia uszkodzenia więzadła krzyżowego, a także innych kontuzji stawu kolanowego i nie tylko (np. OtrhoPets Europe). Od kilkudziesięciu lat wykonuje się zabiegi chirurgiczne mające na celu poprawę komfortu funkcjonowania zwierząt z zerwanym LCC. Metody operacyjne można sklasyfikować w trzech</p>
        <p>głównych grupach:</p>
        <p>• metody zewnątrztorebkowe;</p>
        <p>• metody wewnątrztorebkowe;</p>
        <p>• metody wykorzystujące osteotomię kości piszczelowej (1).</p>
        <p>Wybór metody zależy od wielkości zwierzęcia, jego wieku, kondycji, aktywności, możliwości finansowych właściciela oraz oczywiście od preferencji lekarza operującego. Niezależnie od wybranej metody około 90% zwierząt udaje się wyleczyć, co jest nie tylko pocieszające, ale również dowodzi skuteczności</p>
        <p>każdej z opisanych technik.</p>
        <p><strong style="color: rgb(51, 51, 153);">Metody zewnątrztorebkowe</strong>
        </p>
        <p>
        <br>
        </p>
        <p><strong>Imbrykacja</strong>
        </p>
        <p>&nbsp;Metoda, w której zakładamy szwy na powięź szeroką (fascia lata), co prowadzi do jej napięcia. Powięź zacieśniamy poprzez dachowkowate jej nałożenie na siebie lub przez częściowe wycięcie i zszycie powięzi. Metoda rzadko stosowana pojedynczo (jeżeli już, to u małych psów i kotów), natomiast w połączeniu z innymi technikami służy do zwiększenia stabilizacji stawu i ograniczenia wysuwania piszczeli do przodu.</p>
        <p>&nbsp;</p>
        <p><strong>Szew boczny, zewnętrzny szew stabilizujący.</strong>
        </p>
        <p>&nbsp;Obecnie jest to najszerzej stosowana w Polsce technika naprawy uszkodzonego LCC. Posiada wiele odmian oraz modyfikacji, jednak wszystkie one bazują na podobnych zasadach. Podczas tego zabiegu wykonujemy stabilizację stawu kolanowego, poprzez założenie pętli z niewchłanialnego materiału.</p>
        <p>W zależności od modyfikacji oraz użytych materiałów pętla prowadzona jest wokół bocznej trzeszczki podkolanowej Vesala lub kotwiczona jest w kłykciu bocznym kości udowej i biegnie doczaszkowo, przechodząc przez kanał kostny znajdujący się w guzowatości piszczelowej (pierwotnie pętla zakładana była do dalszej części więzadła prostego rzepki). Możliwe jest zastosowanie specjalnej śruby kostnej z oczkiem, którą kotwiczymy w punkcie izomerycznym na bocznym kłykciu kości udowej (Canine Cranial Cruciate Ligament Repair Anchor System firmy Arthrex). Zapobiega to częstemu powikłaniu w postaci wyrwania lub zsunięcia się pętli z trzeszczki. Zastosowanie znajdują również systemy Ligafibia Latelar Button, Ligafi - bia Iso-Toggle.</p>
        <p>
        <br>
        </p>
        <p><strong style="color: rgb(0, 0, 0);">Wysunięcie głowy kości strzałkowej</strong>
        </p>
        <p>Zabieg ma na celu przesunięcie, w kierunku doczaszkowym, dalszego przyczepu więzadła pobocznego bocznego (lig. Collaterale laterale). Bliższy przyczep tego więzadła jest położony na bocznym kłykciu kości udowej, a przyczep dalszy – na głowie kości strzałkowej. Doczaszkowe przemieszczenie głowy kości strzałkowej prowadzi do napięcia tego więzadła i przejęcia przez niego sił destabilizujących staw. Po transpozycji głowy kości strzałkowej mocuje się ją za pomocą gwoździa Kirschnera i pętli z drutu. Metoda ta wymaga pewnej wprawy od operującego (2), lecz znajduje zastosowanie u psów różnej wielkości i wagi.</p>
        <p><strong style="color: rgb(51, 51, 153);">Metody wewnątrztorebkowe</strong>
        </p>
        <p>Do metod wewnątrztorebkowych wykorzystujemy materiał własny pacjenta (część więzadła prostego rzepki lub fragment powięzi szerokiej) lub materiały syntetyczne. Zabiegi najczęściej przeprowadza się z wykorzystaniem artrotomii, rzadziej za pomocą artroskopii. Jedną z bardziej popularnych metod jest metoda Over-The-Top. Przeszczep autologiczny ulega wtórnemu unaczynieniu po czasie 12-14 tygodni (5). Przed upływem tego okresu staw narażony jest na destabilizację, a przeszczep na uszkodzenie.</p>
        <p><strong style="color: rgb(51, 51, 153);">Metody wykorzystujące osteotomię kości piszczelowej</strong>
        </p>
        <p>Tibial Plateau Leveling Osteotomy (TPLO) Technika wprowadzona i opatentowana w roku 1993 jako ewolucja Tibial Wedge Osteotomy (TWO) przez Slocum (5). Założeniem metody jest likwidacja CTT poprzez zmianę TPA do wartości 5-6°. Osiąga się to poprzez osteotomię bliższej części kości piszczelowej, a następnie jej rotację. Wynikiem tego postępowania jest osiągnięcie stabilności stawu kolanowego w trakcie jego obciążania. Zadowalające są również rezultaty długoterminowe w postaci zmniejszenia liczby psów, u których dochodzi do rozwinięcia zmian degeneracyjnych stawu. Obecnie jest to jedna z najbardziej popularnych na świecie metod wykorzystujących osteotomię kości piszczelowej.</p>
        <p>&nbsp;</p>
        <p><strong style="color: rgb(51, 51, 153);">Triple Tibial Osteotomy (TTO)</strong>
        </p>
        <p>Potrójna osteotomia piszczeli łączy w sobie cele TTA i TWO</p>
        <p>(Tibial Wedge Ostotomy). Prowadzi do uzyskania kąta 90° pomiędzy TP a więzadłem prostym rzepki.</p>
        <p>W efekcie niwelujemy CTT.</p>
        <p><strong style="color: rgb(51, 51, 153);">Tibial Tuberosity Advancement (TTA)</strong>
        </p>
        <p>Przesunięcie przednie guzowatości piszczelowej jest zabiegiem opracowanym przez Tepica w roku 2002. Ideą zabiegu jest wysunięcie guzowatości kości piszczelowej w stosunku do powierzchni stawowej kości piszczelowej w taki sposób, aby kąt pomiędzy TP a więzadłem prostym rzepki wynosił 90°. W tym celu wykonuje się osteotomię kości piszczelowej, oddzielając guzowatość.</p>
        <p>Mocowanie odciętej guzowatości uzyskujemy przez zastosowanie odpowiednio wyprofilowanej płyty. Jest ona mocowana do guzowatości za pomocą śrub lub grzebienia, a dalszy koniec przytwierdzony jest do trzonu kości piszczelowej. Odległość, na jaką powinniśmy wysunąć guzowatość (a co za tym idzie przyczep więzadła prostego rzepki), wyznaczamy za pomocą szablonu. Pomiędzy kość piszczelową</p>
        <p>a oddzieloną guzowatość wprowadzana jest klatka tytanowa. Uzyskujemy w ten sposób zmianę biomechaniki stawu i likwidację CTT. Na pierwotnym założeniu Tepica opiera</p>
        <p>się obecnie wiele wariantów operacyjnych. Oryginalną klatkę zastąpiła tytanowa gąbka (OfthoFoam firmy Orthomed), co znacznie ułatwiło procedurę (6), zrezygnowano z płyty (MMP – Modyfi ed Maquet Technique) lub zmieniono kształt klatki i rodzaj jej mocowania do kości (TTA Rapid).</p>
        <p>&nbsp;</p>
        <p><strong style="color: rgb(51, 51, 153);">Tibial Tuberosity Advancement (TTA Rapid)</strong>
        </p>
        <p>Jak zaznaczono powyżej, celem zabiegu jest uzyskanie kąta prostego pomiędzy powierzchnią stawową kości piszczelowej a więzadłem prostym rzepki. Przystępując do planowania zabiegu, musimy zacząć od wykonania radiogramu stawu kolanowego w projekcji bocznej i strzałkowej. Na zdjęciu w pozycji bocznej powinna być widoczna nasada dalsza kości udowej, której kłykcie powinny być nałożone na siebie, oraz widoczna cała kość piszczelowa. Kąt pomiędzy kością udową a piszczelową powinien wynosić 135°, chociaż możliwa jest ocena radiogramów, na których kąt ten ma inną wartość (metoda osi kości piszczelowej). Przedoperacyjna ocena radiogramów pozwala na ocenę wielkości implantów koniecznych do założenia. Wysokiej jakości zdjęcia ułatwiają wykonanie obliczeń. W ich trakcie uzyskujemy informację o dwóch z trzech wymiarów, koniecznych do przeprowadzenia zabiegu. Pierwsza liczba to wielkość wysunięcia guzowatości kości piszczelowej. Właściwie można wykorzystać jedną z wielu metod, które służą do oceny wielkości wysunięcia guzowatości kości piszczelowej. Zasadą jest uzyskanie kąta prostego pomiędzy więzadłem prostym rzepki a płaszczyzną stawową kości piszczelowej. Możemy do tego celu użyć klasycznych szablonów TTA (w przypadku radiografii cyfrowej firma KYON zapewnia oprogramowanie ułatwiające te obliczenia), jak również metody Common Tangent, lub zaproponowanej przez Inauera i Kocha metody 2,07 x TP (7), a także innych. W klinice autora korzysta się z techniki, która pozwala na obliczenie stopnia wysunięcia, niezależnie od kąta zawartego na radiogramie, pomiędzy kością udową a kością piszczelową (w większości metod kończyna powinna być ułożona tak, aby kąt ten wynosił 135°). Jest to tzw. metoda osi kości piszczelowej (Tibial Axis Metod), preferowana również przez autora TTA Rapid, doktora Yvesa Samoya.</p>
        <p>Po wykonaniu tych obliczeń znamy wielkość wysunięcia guzowatości kości piszczelowej, a co za tym idzie – wielkość klatki. Są one produkowane w rozmiarach: 3; 4,5; 6; 7,5; 9; 10,5; 12; 13,5 i 15 mm, a każda z tych klatek ma trzy warianty związane z jej głębokością (ryc. 7).</p>
        <p><img src="/resources/images/7f963377daab750067659d53fc7c9d3e.jpeg">
        </p>
        <p><em style="color: rgb(7, 7, 7);">Ryc. 7. Klatki tytanowe zaprojektowane do metody TTA Rapid</em>
        </p>
        <p>
        <br>
        </p>
        <p><span style="color: rgb(7, 7, 7);">&nbsp;Kolejnym wymiarem, który musimy znać przed zabiegiem, jest grubość warstwy korowej kości piszczelowej w miejscu wykonania otworu Maqueta.</span>
        </p>
        <p><span style="color: rgb(7, 7, 7);">&nbsp;Aby określić położenie otworu Maqueta, korzystamy z szablonów dostarczanych przez dystrybutora implantów. Szablon ten określa położenie otworu w zależności od rozmiaru klatki, dzięki temu możemy wyznaczyć grubość warstwy korowej kości piszczelowej w jej doczaszkowej części. Do prawidłowego wykonania zabiegu potrzebujemy jeszcze trzeciego wymiaru, określającego głębokość klatki, niestety tę wartość możemy uzyskać jedynie śródoperacyjnie.</span>
        </p>
        <p>
        <br>
        </p>
        <p><strong style="color: rgb(51, 51, 153);">Technika zabiegu</strong>
        </p>
        <p>Przygotowanie psa do zabiegu oraz metody znieczulania nie wymagają ponadstandardowych procedur. Operowana kończyna powinna zostać wygolona, poczynając od stawu biodrowego aż poza staw stępu. Przed dezynfekcją kończyna powinna zostać dokładnie umyta przy użyciu preparatów zawierających chlorheksydynę. Do ostatecznej dezynfekcji można użyć jednego z wielu dostępnych na rynku preparatów, zawierających mieszaninę alkoholi, często stosowany roztwór jodyny również się sprawdza. Zwierzę układamy w pozycji grzbietowej z operowaną kończyną uniesioną tak, aby możliwy był swobodny dostęp do stawu kolanowego od strony przyśrodkowej. W zależności od tego, czy zabieg poprzedzony będzie artroskopią, czy artrotomią, wybieramy dogodne dla operującego ułożenie. Inspekcja stawu jest niezbędna i ma na celu usunięcie resztek zerwanego więzadła (przy częściowym uszkodzeniu niektórzy autorzy zalecają pozostawienie nieuszkodzonej części) (8), sprawdzenie stanu chrząstki na bocznym i przyśrodkowym kłykciu kości udowej oraz ocenę łąkotek. Uszkodzone łąkotki należy zoperować w sposób mało inwazyjny, w wyjątkowych przypadkach jest konieczna częściowa lub całkowita meniscektomia. Następnie zwierzę układamy w pozycji bocznej z operowaną kończyną leżącą na stole. Cięcie prowadzimy od rzepki do końca dalszego guzowatości kości piszczelowej. Po odsłonięciu kości piszczelowej możemy wstępnie przymierzyć klatkę w celu wykluczenia błędu w obliczaniu jej rozmiaru. W tym celu chwytamy kość palcem wskazującym i kciukiem, opierając palce na doczaszkowej i doogonowej powierzchni kości,</p>
        <p>po ułożeniu klatka powinna być proporcjonalna w stosunku do kości. Wprowadzamy do torebki stawowej gwóźdź o średnicy 1,2 mm, w okolicę przyczepu więzadła prostego rzepki. Będzie on służył do zakotwiczenia szablonu prowadzącego wiertło. Lokalizacja oraz prawidłowa pozycja gwoździa są niezwykle istotne, wyznaczają doczaszkową granicę położenia klatki oraz przebieg osteotomii. Dalszym krokiem jest użycie szablonu (ryc. 2).</p>
        <p>
        <br>
        </p>
        <p><img src="/resources/images/624aa160ef5510334e7c7165aa8a04fd.jpeg">
        </p>
        <p><em style="color: rgb(7, 7, 7);">Ryc. 2. Szablon do wykonania otworu Maqueta założony na gwóźdź prowadzący i z założonym ogranicznikiem</em>
        </p>
        <p>
        <br>
        </p>
        <p><span style="color: rgb(7, 7, 7);">Jest to kluczowa część procedury. Wcześniejsze pomiary służą do określenia otworu w szablonie, który użyjemy do prawidłowego ułożenia szablonu. Na długim ramieniu szablonu umieszczone są otwory skorelowane z rozmiarem klatki. Krótkie ramię służy do przeprowadzenia ogranicznika, zgodnie z wcześniej wyznaczoną grubością kory kości piszczelowej, w jej części doczaszkowej.</span>
        </p>
        <p><span style="color: rgb(7, 7, 7);">Po ułożeniu szablonu wykonujemy otwór, który będzie ograniczał część dalszą osteotomii kości piszczelowej. Otwór ten ma za zadanie rozproszenie naprężeń kości, które powstaną po otwarciu miejsca osteotomii. W zależności od wielkości zwierzęcia do wykonania otworu używamy wiertła o średnicy 2 lub 3 mm. Po wykonaniu otworu usuwamy szablon. W miejscu wywierconego otworu umieszczamy stopę prowadnicy piły. Prowadnica ta składa się z długiej części zaopatrzonej w szczelinę do prowadzenia ostrza piły oscylacyjnej oraz ze wspomnianej stopy. W zależności od średnicy otworu użyjemy stopy z dwu- lub trzymilimetrowym sworzniem. W stopie umieszczamy część właściwą prowadnicy. W stopie znajduje się również wycięcie w kształcie litery U, stopa musi być ułożona tak, aby wycięcie umożliwiało doprowadzenie ostrza piły do centrum otworu. Do dalszej części procedury konieczne jest wprowadzenie kolejnego gwoździa, na którym założona będzie bliższa część prowadnicy piły. Gwóźdź ten, o średnicy 2,5 mm, wprowadzamy w okolicę guzka Gerdy’ego. Guzek ten leży mniej więcej w połowie odległości między guzowatością kości piszczelowej a brzegiem doogonowym tejże kości i jest z łatwością wyczuwalny po jej stronie bocznej. Najbardziej pożądana pozycja gwoździa to płaszczyzna prostopadła do kości piszczelowej (a właściwie prostopadła do płaszczyzny strzałkowej kości) oraz stycznie i doczaszkowo od guzka Gerdy’ego. Właściwa część prowadnicy jest teraz ustalana w części bliższej przez gwóźdź, a w części dalszej – przez stopę (ryc. 3)</span>
        </p>
        <p>
        <br>
        </p>
        <p><img src="/resources/images/f9117ff408230eff7d5a03cea8b7d7a4.jpeg">
        </p>
        <p><em>Ryc. 3. Prowadnica ostrza piły oscylacyjnej</em>
        </p>
        <p>
        <br>
        </p>
        <p>Należy upewnić się, że szczelina prowadnicy, a co za tym idzie – płaszczyzna cięcia, jest prostopadła do płaszczyzny strzałkowej kości piszczelowej. Ma to niebagatelne znaczenie, gdyż wykonanie osteotomii pod niewłaściwym kątem skutkuje powikłaniem w postaci zwichnięcia rzepki. Podczas wykonywania osteotomii nie ma potrzeby ustalania prowadnicy przy użyciu dodatkowych narzędzi (ryc. 4).</p>
        <p>
        <br>
        </p>
        <p><img src="/resources/images/c89dd0e2561728af276fb3d56c09ddc3.jpeg">
        </p>
        <p><em>Ryc. 4. Wykonywanie osteotomii za pomocą piły oscylacyjnej</em>
        </p>
        <p>
        <br>
        </p>
        <p>&nbsp;W trakcie cięcia ostrze piły powinno być chłodzone, aby nie dopuścić do martwicy tkanek, którą może wywołać wysoka temperatura.</p>
        <p>Do tego celu używamy roztworu soli fizjologicznej, podawanego pod ciśnieniem</p>
        <p>przez asystenta. Gdy wykonamy cięcie, usuwamy prowadnicę i rozpoczynamy otwieranie miejsca osteotomii. Jest to ta część zabiegu, która wymaga cierpliwości i czasu. Zawsze zaczynamy od najwęższej dźwigni. Umieszczamy ją płasko w szczelinie cięcia na jej bliższym końcu. Przekręcając rączkę dźwigni w dół (w stronę stopy), stopniowo powiększamy odległość między brzegami cięcia. Czynność tę należy wykonywać tak, aby kość miała możliwość dostosować się do naprężeń.</p>
        <p>&nbsp;W zestawie do wykonania zabiegu TTA Rapid dostępne są dźwignie o rozmiarach 3, 6, 9, 12 mm. W zależności od oczekiwanego rozwarcia miejsca osteotomii stosujemy kolejne dźwignie.</p>
        <p>Absolutnie nie należy pomijać pośrednich rozmiarów, nawet jeśli docelowo chcemy zastosować najszerszą dźwignię. Czas, w którym kość będzie adaptowała się do naprężeń, można wykorzystać do przygotowania klatki. Prawidłowe dobranie klatki oznacza, że powinna ona być odpowiedniej szerokości i głębokości (ryc. 5).</p>
        <p><img src="/resources/images/1dffc3ffdbd072f38293c7241858cd07.jpeg">
        </p>
        <p><em>Ryc. 5. Osadzanie tytanowej klatki</em>
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;O ile szerokość wyznaczyliśmy już przed zabiegiem, o tyle głębokość klatki jest możliwa do ustalenia tylko śródoperacyjnie. Pomiaru głębokości wykonujemy w bliższej, doogonowej części cięcia. Następnie należy wyprofilować uszy klatki. W tym celu używamy narzędzia, które na obydwu końcach zaopatrzone jest w szczeliny odpowiadające rozmiarem uszom klatek. Uszy w części doczaszkowej klatki należy zagiąć do dołu i odpowiednio, uszy w części doogonowej klatki wyginamy do góry. Pomyłka na tym etapie może skutkować koniecznością ponownego rozginania uszu, co może doprowadzić do ich pęknięcia lub złamania. Okostną odpreparowujemy jedynie z miejsca implantacji klatki, tylko w miejscach, gdzie ułożone będą uszy klatki. Klatka powinna być umieszczona około 3-5 milimetrów poniżej górnej granicy cięcia. W celu dociśnięcia klatki do kości należy użyć kleszczy do płyt kostnych, natomiast w celu dociśnięcia odłamów kostnych można użyć dużego ustalacza kostnego. Przed przystąpieniem do wiercenia otworów pod śruby należy upewnić się, że pozycja klatki jest właściwa. Rozpoczynamy od założenia górnej doczaszkowej śruby, następnie górnej doogonowej, następnie kolejnej doczaszkowej – i tak zygzakiem podążamy do najniższej doogonowej śruby. Kierunek przebiegu śrub powinien spełniać zasadę „od stawu i od osteotomii”, czyli doczaszkowe śruby zakładamy, wiercąc otwory w kierunku cranio-dystalnym, natomiast doogonowe – w kierunku caudo-dystalnym (ryc. 6).</p>
        <p><img src="/resources/images/37471ea3c70744cab6f25032c52d8cb3.jpeg">
        </p>
        <p><em>Ryc. 6. Klatka jest przymocowana do kości za pomocą tytanowych śrub</em>
        </p>
        <p>
        <br>
        </p>
        <p>&nbsp;Po założeniu wszystkich śrub usuwamy kościotrzymacz. W celu skrócenia okresu gojenia miejsce osteotomii możemy wypełnić autologicznym przeszczepem kostnym, co nie jest zalecane (9), lub pastą hydroksyapatytową.</p>
        <p>Ranę zamykamy, rozpoczynając od części dalszej cięcia, zakładając szwy krzyżowe na powięź. Ponieważ ilość tkanek miękkich odpreparowanych od kości jest niewielka, nie zawsze udaje się zakryć implant w całości. Szycie skory zależy od preferencji operującego.</p>
        <p>Kontrolne zdjęcia RTG powinny zostać wykonane w celu upewnienia się co do prawidłowo przeprowadzonego zabiegu (ryc. 8).&nbsp;</p>
        <p><img src="/resources/images/67f2b8534cc3a1f33fbf0a2940ea05e8.jpeg">
        </p>
        <p><em>Ryc. 8. Kontrolne zdjęcie RTG</em>
        </p>
        <p>
        <br>
        </p>
        <p>Posłużą one również jako punkt odniesienia podczas oceny gojenia po kilku tygodniach. Na ranę naklejamy lekki opatrunek, który pozostawiamy na 3-5 dni. Opieka pooperacyjna jest istotną częścią procedury. Pies powinien mieć zapewnione warunki, w których dojdzie do pełnego zrostu kości w miejscu osteotomii oraz przywrócona zostanie pełna sprawność kończyny. Bezpośrednio po zabiegu zwierzę zazwyczaj zostaje w klinice do następnego dnia; pozwala to na stosowną analgezję. Kolejna wizyta ustalana jest po 10 dniach oraz po 6 tygodniach. Przez pierwsze 6 tygodni stosować należy bezwzględne ograniczenie ruchu. Zwierzę może chodzić wyłącznie na smyczy, jednorazowo 5 do 10 minut. Zalecana jest hydroterapia (10), czas i częstotliwość kolejnych sesji ustalane są indywidualnie i również zależą od stanu zwierzęcia przed zabiegiem. Pomiędzy szóstym tygodniem a trzecim miesiącem po zabiegu zwiększamy aktywność psa, jednak cały czas powinien być on wyprowadzany na smyczy. Po wykonaniu RTG w trzecim miesiącu po zabiegu, na którym gojenie jest prawidłowe, pozwalamy na ruch bez smyczy, rozpoczynając od 10 minut pod koniec każdego spaceru. Zwiększamy stopniowo aktywność psa, aż do powrotu do normy.</p>
        <p>&nbsp;TTA Rapid jest nowoczesnym zabiegiem, który umożliwia zwierzęciu odzyskanie pełnej sprawności. Technika operowania oraz narzędzia służące do wykonania operacji są ciągle udoskonalane i modyfikowane. Dzieje się tak dzięki zaangażowaniu lekarzy weterynarii wykonujących zabiegi oraz dzięki monitorowaniu dotychczas zoperowanych psów. Wszelkie komplikacje są analizowane i klasyfikowane. Daje to możliwość optymalizacji zarówno sprzętu, jak i metody operowania. Liczba zoperowanych psów rośnie lawinowo. Do początku 2013 roku wykonano 230 zabiegów, natomiast do września 2013 roku wykonano ich już około 1250. Prawidłowo przeprowadzony zabieg prowadzi do szybkiego powrotu zwierzęcia do pełnej sprawności. W naszej praktyce wykonanie zabiegu TTA Rapid proponujemy właścicielom psów ras dużych, psów pracujących oraz psów, dla których szybki powrót do pełnej aktywności jest priorytetem. Jest to zabieg z wyboru dla psów, których tryb życia powoduje, że operowany staw będzie poddawany znacznym obciążeniom. Dotychczasowe doświadczenia lecznicy autora pokazują bardzo dobre rezultaty, jakie można uzyskać dzięki zastosowaniu tej metody operacyjnej. Istnieje jednak konieczność przeprowadzenia analiz w dłuższym okresie. Dadzą one odpowiedź na pytanie o długoterminowe rokowania dla operowanych psów. Uzyskanie długotrwałej poprawy i zapewnienie komfortu życia na wiele lat to obecnie kluczowy problem dla lekarzy weterynarii zajmujących się ortopedią.</p>
        <p>&nbsp;</p>
        <p>Artykuł lek. wet. Rafała Korty dla "Weterynaria w Praktyce"</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong><em>Piśmiennictwo</em></strong>:</p>
        <p><em>1. Fossum T.W.: Chirurgia małych zwierząt.</em>
        </br><em>Elsevier Urban &amp; Partner, Wrocław 2010,</em>
        </br><em>s. 1393-1414.</em>
        </br><em>2. Bojrab J.M., Ellison G.W., Slocum B.: Current</em>
        </br><em>Techniques in Small Animal Surgery.</em>
        </br><em>Williams &amp; Wilkins 1998, s. 1187-1215.</em>
        </br><em>3. Adamiak Z.: Ortopedia i traumatologia</em>
        </br><em>stawow kończyn i układu ściegnowo-mięśniowego</em>
        </br><em>psow i kotow. Galaktyka, Łodź 2006.</em>
        </br><em>4. Beale B.S.: Arthroscopic – assisted TPLO</em>
        </br><em>in small breed dogs. ESVOT Congress</em>
        </br><em>Proceedings, 2012.</em>
        </br><em>5. Houlton J.E.F. i wsp.: BSVA Manual</em>
        </br><em>Of Canine and Feline Musculoskeletal</em>
        </br><em>Disorders. BSVA, 2006.</em>
        </br><em>6. Ness M.: MMP – simplified TTA using</em>
        </br><em>a novel Titanium foam implant. ESVOT</em>
        </br><em>Congress Proceedings, 2012.</em>
        </br><em>7. Inauen R., Koch D.A., Bass M. i wsp.: Tibial</em>
        </br><em>tuberosity conformation as a risk factor</em>
        </br><em>for cranial cruciate ligament rupture in the</em>
        </br><em>dog. „V. C. O. T.”, 2009.</em>
        </br><em>8. Vanini R.: Partial ACL tears: debride or leave</em>
        </br><em>ligament?. ESVOT Congress Proceedings,</em>
        </br><em>2012.</em>
        </br><em>9. Guerrero T.G.: Comparision of Healing</em>
        </br><em>of the Osteotomy Gap after Tibial Tuberosity</em>
        </br><em>Advancement with and without Use</em>
        </br><em>of an Autogenous Cancellous Bone Graft.</em>
        </br><em>„Veterinary Surgery”, 2011, 40.</em>
        </br><em>10. Millis D.L., Levine D., Taylor R.A.: Rehabilitacja</em>
        </br><em>psów. Urban &amp; Partner, Wrocław 2007.</em>
        </p>
        <p>
        <br>
        </p>
        <p><strong><em>lek. wet. Rafał Korta</em></strong>
        </p>
        <p><strong><em>Lecznica Weterynaryjna Arwet</em></strong>
        </p>
        <p><strong><em>32-020 Wieliczka, ul. Sienkiewicza 25</em></strong>
        </p>`,
    link: '/artykuly/tta_rapid_-_weterynaria_w_praktyce'
}, {
    id: 1351,
    imgUrl: '/resources/images/22f66e1c5656bea6cc23065d3142efcc.jpeg',
    title: 'Zapaść tchawicy',
    copy: 'Zapaść tchawicy u psów jest to częsty problem psów ras miniaturowych oraz toy',
    date: '20 sierpnia 2017',
    content: `<p><img src="/resources/images/22f66e1c5656bea6cc23065d3142efcc.jpeg">
        </p>
        <p>
        <br>
        </p>
        <p>Zapaść tchawicy to postępujaca, degeneratywna choroba obejmujaca chrząstki tchawicze, które tracąc sztywność ulegają spłaszczeniu, powodując zmniejszenie światła tchawicy upośledzając prawidłowy przepływ powietrza do płuc podczas oddychania. Często dochodzi również do zapalenia błony śluzowej co utrudnia prawidłowe oczyszczanie się dróg oddechowych. Choroba może prowadzić do poważnej niewydolności&nbsp;oddechowej objawiającej się: dusznością o zróżnicowanym nasileniu skorelowaną ze stopniem zwężenia światła tchawicy, uporczywym napadowym kaszlem często porównywany do "krzyku gęsi" nasilającym się podczas stresu, emocji lub wysiłku fizycznego.</p>
        <p>
        <br>
        </p>
        <p>
        <br>
        </p>
        <p>Badanie RTG pomaga ustalić rozpoznanie, jednak mając na uwadze jego dynamiczny charakter, do ostatecznego postawienia diagnozy oraz zaplanowania zabiegu chirurgicznego konieczne jest specjalistyczne badanie polegające na wprowadzeniu do światła tchawicy endoskopu pozwalającego dokładnie ocenić w czasie rzeczywistym stopień zwężenia pierścieni tchawiczych oraz stan błony śluzowej podczas oddychania.</p>
        <p>Najlepszą metodą poprawienia komfortu życia pacjenta ze stwierdzoną zapadalnością tchawicy III lub IV stopnia jest implantacja samorozprężającego się w tchawicy stentu. Zabieg trwa około pół godziny i odbywa się pod kontrolą radiologiczną</p>
        <p>Przygotowanie do zabiegu stentowania tchawicy polega na przeprowadzeniu dokładnego badania klinicznego, wykonania podstawowego badania krwi, badania kardiologicznego oraz sanacji jamy ustnej aby ograniczyć ryzyko zakażenia pochodzącego z jamy ustnej.</p>
        <p>Pacjenci już po kilku dniach po zabiegu powracają do normalnej aktywności fizycznej znacznie zmniejsza się u nich uczucie duszności.</p>
        <p><img src="/resources/images/443cd87fa1d83a450db328d16703ed47.gif">
        </p>`,
    link: '/artykuly/zapasc_tchawicy'
}]