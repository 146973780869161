import React, { Component } from 'react'
import Slider from 'react-slick'

import NewsArticle from '../simple/NewsArticle'

import articles from '../../utils/articles'

class NewsSection extends Component {
    constructor(props) {
        super(props)
        this.newsArticles = articles
    }

    render() {
        const settings = {
            slidesToShow: 3,
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }]
        }

        return (
            <div className="news-section">
                <div className="news-section__content">
                    <h1 className="title--light news-section__title">Aktualności</h1>
                    <div className="news-section__articles">
                        <Slider {...settings}>
                            {this.newsArticles.map(article => 
                                <div key={article.id} className="news-section__article-container">
                                    <NewsArticle {...article} />
                                </div>
                            )}
                        </Slider>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewsSection