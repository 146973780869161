import React, { Component } from 'react'
import { Link } from 'react-router-dom'

const NewsArticle = ({imgUrl, title, copy, date, link}) => {
    return (
        <div className="news-article" >
            <div 
                className="news-article__image"
                style={{backgroundImage: `url(${imgUrl})`}}>
                {/* <img src={imgUrl} alt=""/> */}
            </div>
            <div className="news-article__content">
                <div className="news-article__title">
                    {title}
                </div>
                <div className="news-article__copy">
                    {copy}
                </div>
                <div className="news-article__date">
                    {date}
                </div>
                <div className="news-article__cta">
                    <Link className="button button--rounded" to={link}></Link>
                </div>
            </div>
        </div>
    )
}

export default NewsArticle