import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import ArtykulPage from './ArtykulPage'
import articles from '../../utils/articles'

class ArtykulyPage extends Component {
    render() {
        console.log(articles)
        return (
            <div>
                <Switch>
					{/* <Route exact path="/" component={(<div>Artykuly</div>)} /> */}
                    {articles.map((article, index) => {
                            console.log(article)
                            return <Route
                                key={`route_${index}`}
                                path={`/artykuly/${article.url}`}
                                component={() => {
                                    return <ArtykulPage
                                        article={article}
                                    />}
                                }
                            />
                        })
                    }
					{/* <Route
                        path="/artykuly/tta_rapid_w_pytaniach_i_odpowiedziach" 
                        component={<ArtykulPage
                            article={articles[0]}
                        />}/> */}
				</Switch>
            </div>
        )
    }
}

export default ArtykulyPage