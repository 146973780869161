import React, { Component } from 'react'
import Slider from 'react-slick'

import CasesArticle from '../simple/CasesArticle'

class CasesSection extends Component {
    render() {
        const settings = {
            slidesToShow: 2,
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }]
        }
        console.log(this.props.cases)
        /*let casesToRender = this.props.cases
            .map(el => {
                // return <CasesArticle key={el.id} {...el}></CasesArticle>
                return (
                    <div className="cases-article">
                        <div key={el.id} className="cases-article__container">
                            <div className="cases-article__image">

                            </div>
                            <div className="cases-article__copy">
                                <div className="cases-article__title">
                                    {el.title}
                                </div>
                                <div className="cases-article__copy">
                                    {el.copy}
                                </div>
                                <div>
                                    <span className="cases-article__date">
                                        {el.date}
                                    </span> 
                                    |
                                    <span className="cases-article__author">
                                        {el.author}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        console.log(casesToRender)*/
        console.log(this.props)
        return (
            
            <div className="cases-section">
                <div className="cases-section__container">
                    <h1 className="cases-section__title">Ciekawe przypadki</h1>
                    {this.props.cases ?
                            <Slider {...settings}>
                                {/*{casesToRender}*/}
                                {(this.props.cases || []).map(el => {

                                    return (
                                        <div key={el.id}>
                                            <CasesArticle {...el}></CasesArticle>
                                        </div>
                                    )
                                })}
                            </Slider>
                    :
                        <div>No slider yet</div>
                    }
                    {/*Todo lista ciekawych przypadków w sliderze*/}
                    {/*<Link to="/articles" className="cases-section__cta-articles">Zobacz wszystkie artykuły</Link>*/}
                </div>
            </div>
        )
    }
}

export default CasesSection