import React, { Component } from 'react'

class ServicesSection extends Component {
    render() {
        return (
            <div>Usługi</div>
        )
    }
}

export default ServicesSection