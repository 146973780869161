import React, { Component } from 'react'

import NewsSection from '../sections/NewsSection'
import RegisterSection from '../sections/RegisterSection'
import CasesSection from '../sections/CasesSection'
import ServicesSection from '../sections/ServicesSection';

class HomePage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            cases: [{
                title: 'Złamanie kości piszczelowej',
                copy: 'Lorep ipsum dolor sit Lorep ipsum dolor sit',
                date: '11 marca',
                author: 'Rafał Korta',
                id: 123412
            }, {
                title: 'Kość biodrowa',
                copy: 'Lorep ipsum dolor sit orep ipsum dolor sit ',
                date: '12 marfasca',
                author: 'Rafał Korta',
                id: 123411
            }, {
                title: 'Zwichnięcie łapki u królika',
                copy: 'Lorep ipsum dolor sit Lorep ipsum dolor sit',
                date: '13 marca',
                author: 'Rafał Korta',
                id: 112412
            }, {
                title: 'Staw skokowy u kota',
                copy: 'Lorep ipsum dolor sit Lorep ipsum dolor sit',
                date: '14 marca',
                author: 'Rafał Korta',
                id: 121324
            }]
        }
    }
    render() {
        return (
            <div className="home-page">
                <NewsSection /> 
                <RegisterSection/>
                {/* <ServicesSection/> */}
                  {/* <CasesSection cases={this.state.cases}/>  */}
            </div>
        )
    }
}

export default HomePage