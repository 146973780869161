import React, { Component } from 'react'

class RegisterSection extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        let markercenter = {lat: 49.985495, lng: 20.065417}
        let mapcenter = {lat: 49.985191, lng: 20.055439}
        window.initMap(mapcenter, markercenter)
    }

    render() {
        return (
            <div className="register-section">
                <div className="register-section__container">
                    <div className="register-section__copy">
                        <h1 className="register-section__title">Rejestracja</h1>
                        <p><span className="icon icon-bell"></span><a href="tel:122781800">12 278 1800</a></p>
                        <p><span className="icon icon-phone"></span><a href="tel:122781800">12 278 18 00</a></p>
                        <p><span className="icon icon-mail"></span><a href="mailto:recepcja@arwet.pl">recepcja@arwet.pl</a></p>
                        <a className="register-section__register-online-cta button button--rectangle" href="https://www.wettermin.pl/lecznice/wieliczka/830">
                            Rejestracja online
                        </a>
                    </div>
                </div>
                <div className="register-section__map">
                    <div id="map">
                    </div>
                </div>
            </div>
        )
    }
}

export default RegisterSection