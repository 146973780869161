import React, { Component } from 'react'

class ArtykulPage extends Component {
    render() {
    console.log('ARTYKUL HERE!');
        const { content, title } = this.props.article

        console.log(this.props.params)
        return (
            <div className="article-page__container">
                <h1 className="article-page__title">
                    {title}
                </h1>
                <div
                    className="article-page__content services-section__content"
                    dangerouslySetInnerHTML={{__html: content}}>
                </div>
            </div>
        )
    }
}

export default ArtykulPage